<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Configuration_Name')" prop="tenantPaymentName">
                <el-input
                  v-model.trim="formInline.tenantPaymentName"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <!--          TODO-->
              <el-form-item label="支付商户" prop="tenantId">
                <el-select v-model.trim="formInline.tenantId" filterable size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.tenantName"
                    :value="value.tenantId"
                    :key="value.tenantId"
                    v-for="value in payList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="平台商户" prop="operationId">
                <el-select v-model.trim="formInline.operationId" filterable size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  getData();
                  page = 1;
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="resetForm">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left"><el-button @click="add" icon="el-icon-plus" type="primary">{{ $t('button.addto') }}</el-button></div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
          <el-table-column align="center" type="index" label="平台商户" width="300">
            <template slot-scope="scope">
              <el-popover placement="left" width="259" trigger="hover">
                {{ scope.row.operationNames }}
                <div slot="reference" class="name-wrapper">
                  <el-tag size="medium"
                    >{{ scope.row.operationNames.split(",")[0]
                    }}<span v-if="scope.row.operationNames.split(',').length > 1">...</span></el-tag
                  >
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column align="center" :label="$t('searchModule.Creation_time')" prop="createdTime"></el-table-column>
          <el-table-column align="center" :label="$t('searchModule.Update_time')" prop="updatedTime"></el-table-column>
          <el-table-column align="center" :label="$t('searchModule.Operator')" prop="operatorName"></el-table-column>
          <!--                           TODO-->
          <AuthorityComponent
            ComponentName="el-table-column"
            align="center"
            :permission="['button.detail', 'button.edit']"
            label="操作"
            width="80"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0">
                  操作
                  <i class="el-icon-arrow-down" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="detail" v-if="$route.meta.authority.button.view"
                    >查看</el-dropdown-item
                  >
                  <el-dropdown-item command="edit" v-if="$route.meta.authority.button.edit"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item command="delete" v-if="$route.meta.authority.button.delete"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </AuthorityComponent>
        </el-table>
        <div class="pagerWrapper" v-if="total > 0">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat, exportExcelNew } from "../../common/js/public.js";

export default {
  name: "merchantConfiguration",
  data() {
    let startTime = new Date();
    startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
    let date = startTime;
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      index: 0,
      loading: false,
      detailFlag: false,
      areaList: [],
      parkList: [],
      tenantList: [],
      payList: [],
      rsTypeList: {},
      tableCols: [
        {
          prop: "tenantPaymentName",
          label: "配置名称",
          width: "",
        },
        {
          prop: "tenantName",
          label: this.$t("list.Payment_merchant"),
          width: "",
        },
      ],
      tableData: [],
      // TODO
      formInline: {
        tenantPaymentName: "",
        operationId: "",
        tenantId: "", // 支付商户id
      },
      isEdit: false,
      isLook: false,
    };
  },
  methods: {
    // todo
    // 处理列表按钮点击
    handleCommand(event, row) {
      switch (event) {
        case "detail":
          this.toDetail(row.tenantId);
          break;
        case "edit":
          this.toEdit(row.tenantId);
          break;
        case "delete":
          this.getDelete(row.tenantId);
          break;
        default:
          break;
      }
    },
    // add
    add() {
      this.$router.push({ path: "/platformMerchantConfigurationDetail", query: { state: "add" } });
      this.isEdit = false;
    },
    // 进入查看页
    toDetail(tenantId) {
      this.$router.push({
        path: "/platformMerchantConfigurationDetail",
        query: { state: "detail", tenantId: tenantId },
      });
      this.isLook = true;
    },
    // 进入编辑页
    toEdit(tenantId) {
      this.$router.push({
        path: "/platformMerchantConfigurationDetail",
        query: { state: "edit", tenantId: tenantId },
      });
      this.isEdit = true;
    },
    getDelete(tenantId) {
      this.$confirm("此操作将删除该条数据, 是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post("/acb/2.0/tenantPayment/delete", {
              data: {
                tenantId: tenantId,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$message({
                  type: "info",
                  message: "删除成功！",
                });
                this.getData();
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // TODO
    resetForm() {
      this.formInline = {
        tenantPaymentName: "",
        operationId: "",
        tenantId: "", // 支付商户id
      };
    },
    getTimeNow() {
      const endTime = new Date();
      if (this.formInline.dateType == "day") {
        endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
        this.formInline.day = dateFormat(endTime, "yyyy-MM-dd");
      } else {
        endTime.setTime(endTime.getTime() - 31 * 24 * 3600 * 1000);
        this.formInline.date = dateFormat(endTime, "yyyy-MM");
      }
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    /* 支付商户下拉框 */
    getpayList() {
      this.$axios.get("/acb/2.0/tenant/nameList").then((res) => {
        if (res.state == 0) {
          this.payList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    getData() {
      this.$axios
        .get("/acb/2.0/tenantPayment/list/", {
          data: {
            tenantPaymentName: this.formInline.tenantPaymentName,
            tenantId: this.formInline.tenantId,
            operationId: this.formInline.operationId,
            page: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            this.tableData = res.value.list;
            this.total = res.value.total * 1 || 0;
          }
        });
    },
    exportFile() {
      let date = "";
      if (this.formInline.dateType == "month") {
        date = this.formInline.date;
      } else {
        date = this.formInline.day;
      }
      if (date == null) {
        this.$alert("日期不能为空！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      if (!this.formInline.dateType) {
        this.$alert("日期类型不能为空！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      let url =
        "/acb/2.0/financeStatistic/pdaManagers/report/" + this.formInline.dateType + "/" + date;
      let opt = {
        tenantPaymentName: this.formInline.tenantPaymentName,
        name: this.formInline.name,
        operationId: this.formInline.operationId,
      };
      exportExcelNew(url, opt);
    },
  },
  beforeDestroy() {},
  components: {},
  // activated () {
  //   this.getData();
  // },
  created() {
    this.getData();
    this.getTenantList();
    this.getpayList();
    // if (sessionStorage.searchFlag == 1) {
    //   this.formInline = JSON.parse(sessionStorage.searchData);
    // }
  },
  activated() {
    this.getData();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content
  overflow hidden
  .searchWrapper
    overflow hidden
    padding 22px 22px 0
    margin-bottom 20px
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
.el-dropdown-link
  cursor pointer
</style>
